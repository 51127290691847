import React from "react";
import ComingSoon from "react-coming-soon";

export default function App() {
  return (
    <ComingSoon
    image="/bg.jpg"
    bgColor="#fff"
    textColor="#000"
    illustration="/uc.svg"
  />
  );
}
